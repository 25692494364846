<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <!-- BOC:[header] -->
  <v-row class="mb-3">
    <v-col><h1>{{model.name.plural}}</h1></v-col>
    <v-col v-if="model[role].add" class="text-right">
      <v-btn
        v-if="$_hasRoute({name:'Page'+role+model.key+'Add'})"
        color="primary"
        large
        :to="{name:'Page'+role+model.key+'Add'}"
      >
        + New {{model.name.singular}}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        large
        :to="{name:'Page'+role+'DataAdd',params:{object:$_lcfirst(model.key)}}"
      >
        + New {{model.name.singular}}
      </v-btn>
    </v-col>
  </v-row>
  <!-- EOC -->
  <!-- BOC:[tabs] -->
  <v-tabs
    v-model="tab"
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline"
    show-arrows
  >
    <v-tab
      v-for="item in tabs"
      :key="item.key"
      :href="`#${item.key}`"
    >
      {{ item.label }}
    </v-tab>
  </v-tabs>
  <!-- EOC -->
  <!-- BOC:[tab] -->
  <v-tabs-items v-model="tab">
    <!-- BOC:[model] -->
    <v-tab-item
      key="all"
      value="all"
      class="px-1 py-2"
    >
      <BreadBrowseTable
        :role="role"
        :model="model"
        :url="newUrl+'?lang='+this.$_getLocale()"
      ></BreadBrowseTable>
    </v-tab-item>
    <!-- EOC -->
    <!-- BOC:[model] -->
    <v-tab-item
      key="trash"
      value="trash"
      class="px-1 py-2"
    >
      <BreadBrowseTable
        :role="role"
        :model="model"
        :url="newUrl+'/trashed?lang='+this.$_getLocale()"
        :isTrashed="true"
      ></BreadBrowseTable>
    </v-tab-item>
    <!-- EOC -->
  </v-tabs-items>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import models from '@/models/index'
  //EOC
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTable'
  import { mapState } from 'vuex'
  export default {
    metaInfo() {
      return {
        title:this.model.name.plural + ' | Hamochi',
      }
    },
    components:{
      BreadBrowseTable
    },
    computed: mapState({
      //
    }),
    props:[
      'role',
    ],
    data: () => ({
      //BOC:[model]
      model: {},
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[tabs]
      tab:null,
      tabs:[
        {
          key:'all',
          label:'All',
        },
        {
          key:'trash',
          label:'Trash',
        },
      ],
      //EOC
      //BOC:[url]
      newUrl:null,
      //EOC
    }),
    created() {
      //BOC:[model]
      this.model = models[this.$route.params.object]
      //EOC
      //BOC:[guard]
      if(!this.model || !this.model[this.role].browse) {
        this.$router.replace({name:'Page'+this.role+'Dashboard'})
        return
      }
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:'Dashboard',
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.model.name.plural,
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //EOC
      //BOC:[url]
      this.newUrl = (this.url) ? this.url : `${this.$api.servers[this.role.toLowerCase()]}/v1/bread/${this.$route.params.object}`
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>